/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var siteLogo = $('.site-logo'),
		hamburger = $('.hamburger'),
		headerHeight = ( siteLogo.outerHeight() > hamburger.outerHeight() ) ? siteLogo.outerHeight() : hamburger.outerHeight(),
		menuButton = $('.menu-button');
	
	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}



	
	document.addEventListener("DOMContentLoaded", function() {
		if (window.innerWidth > 768) {
			const items = document.querySelectorAll('.item .wrap');
			let cumulativeOffset = 0;  // Distanza cumulativa dall'origine
			
			items.forEach((item, index) => {
				let translateX = 0; // Impostazione iniziale per l'asse X
				if ((index + 1) % 2 === 0) { // Elementi pari
					if (index === items.length - 1) { // Ultimo elemento e pari
						translateX = 5; // Trasformazione specifica per l'ultimo elemento pari
						// Modifica l'ordine se necessario
						const textContainer = item.querySelector('.text_container_room');
						const imageContainer = item.querySelector('.image_container');
						if (textContainer && imageContainer) {
							textContainer.style.order = 2;
							imageContainer.style.order = 1;
						}
					}
				} else { // Elementi dispari
					translateX = -4; // Trasformazione specifica per gli elementi dispari
				}
			
				// Calcola l'offset verticale per ciascun elemento
				let currentOffset = cumulativeOffset;
				item.style.transform = `translate3d(${translateX}em, -${currentOffset}px, 0)`;
				cumulativeOffset += (item.offsetHeight - 540); // Aggiunge l'altezza dell'elemento meno un margine di sovrapposizione
			});
		}
	});
	
	document.addEventListener("DOMContentLoaded", function() {
		if (window.innerWidth > 768) {
			const items = document.querySelectorAll('#item.item_offer .wrap');
			let cumulativeOffset = 0; // Distanza cumulativa dall'origine
	
			items.forEach((item, index) => {
				let translateX = 0; // Impostazione iniziale per l'asse X
	
				// Controllo se il dispositivo ha una risoluzione maggiore di 1920x1080
				let offsetValue = window.innerWidth > 1920 && window.innerHeight > 1080 ? 540 : 540;
	
				if ((index + 1) % 2 === 0) { // Elementi pari
					if (index === items.length - 1) { // Ultimo elemento e pari
						translateX = 5; // Trasformazione specifica per l'ultimo elemento pari
						// Modifica l'ordine se necessario
						const textContainer = item.querySelector('.text_container_room');
						const imageContainer = item.querySelector('.image_container');
						if (textContainer && imageContainer) {
							textContainer.style.order = 2;
							imageContainer.style.order = 1;
						}
					}
				} else { // Elementi dispari
					translateX = -4; // Trasformazione specifica per gli elementi dispari
				}
	
				// Calcola l'offset verticale per ciascun elemento
				let currentOffset = cumulativeOffset;
				item.style.transform = `translate3d(${translateX}em, -${currentOffset}px, 0)`;
				cumulativeOffset += (item.offsetHeight - offsetValue); // Aggiunge l'altezza dell'elemento meno un margine di sovrapposizione
			});
		}
	});
	
	
	$(document).ready(function() {
		function revealElementOffer() {    
			var containers = gsap.utils.toArray('.reveal_element_offer');
			if (containers.length > 0) {
				containers.forEach((container) => {
					var tl = gsap.timeline({
						scrollTrigger: {
							trigger: container,
							start: 'top center'
						}
					});
	
					var clipPathValue = container.classList.contains('right-side') 
						? 'inset(0% 0% 0% 100%)' 
						: 'inset(0% 100% 0% 0%)';
	
					// Set initial clip-path to hide the element
					gsap.set(container, { clipPath: clipPathValue });
	
					var endClipPathValue = 'inset(0% 0% 0% 0%)'; // Reveal the entire element
	
					tl.to(container, { 
						clipPath: endClipPathValue, 
						duration: 0.8, 
						ease: 'power4.out'
					});
				});
			}
		}
	
		revealElementOffer();
	});
	
		
	
	
 	


	// Code to run when the document is ready

	$( document ).ready(function() {

		function revealLogo() {
			var logo = document.querySelector('.site-logo img');
			if (logo) {
				var tl = gsap.timeline();
				// Set initial opacity to 0 to hide the logo
				gsap.set(logo, { opacity: 0 });
		
				tl.to(logo, { 
					opacity: 1,
					duration: 0.8, 
					ease: 'power4.out'
				});
			}
		}
		setTimeout(() => {
			revealLogo();
		}, 3500);
		
		// Images reveal
		function revealImages() {	
			var containers = gsap.utils.toArray('.reveal');
			if ( containers.length > 0 ) {
				containers.forEach((container) => {
					var image = container.querySelector('img'),
						tl = gsap.timeline({
							scrollTrigger: {
								trigger: container,
								start: 'top bottom'
							}
						});
					
					tl.set(
						container,
						{
							autoAlpha: 1
						}
					);
					if ( container.classList.contains('right-side') ) {
						tl.from(
							container,
							1,
							{
								xPercent: 100,
								ease: 'power1.inOut'
							}
						);
						tl.from(
							image,
							1,
							{
								xPercent: -100,
								delay: -1,
								ease: 'power1.inOut'
							}
						);
					} else {
						tl.from(
							container,
							1,
							{
								xPercent: -100,
								ease: 'power1.inOut'
							}
						);
						tl.from(
							image,
							1,
							{
								xPercent: 100,
								delay: -1,
								ease: 'power1.inOut'
							}
						);
					}
				});
			}
		}

		//element reveal

		// Images reveal

			function revealElement() {	
				var containers = gsap.utils.toArray('.reveal_element');
				if ( containers.length > 0 ) {
					containers.forEach((container) => {
						var image = container.querySelectorAll('.reveal_element_revealed'),
							tl = gsap.timeline({
								scrollTrigger: {
									trigger: container,
									start: 'top bottom'
								}
							});
						
						tl.set(
							container,
							{
								autoAlpha: 1
							}
						);
						if ( container.classList.contains('right-side') ) {
							tl.from(
								container,
								1,
								{
									xPercent: 100,
									ease: 'power1.inOut'
								}
							);
							tl.from(
								image,
								1,
								{
									xPercent: -100,
									delay: -1,
									ease: 'power1.inOut'
								}
							);
						} else {
							tl.from(
								container,
								1,
								{
									xPercent: -100,
									ease: 'power1.inOut'
								}
							);
							tl.from(
								image,
								1,
								{
									xPercent: 100,
									delay: -1,
									ease: 'power1.inOut'
								}
							);
						}
					});
				}
			}


		
		let headerHeight = 100; // Assicurati di definire headerHeight correttamente

		// Funzione per mostrare le immagini introduttive
		function showIntroImages() {
			let delayToShow = 0;
			const totalDuration = 2; // Durata totale in secondi
			const animationDuration = 0.5; // Durata dell'animazione per ogni immagine
			const delayBetweenImages = (totalDuration - animationDuration) / 3; // Dividi il tempo restante equamente tra le immagini
		
			$('#intro-images .intro-image').each(function(index) {
				// Rimuovi le impostazioni iniziali CSS non necessarie in questo contesto
		
				if (index === 1 || index === 3) {
					$(this).addClass('bigger-image');
				} else {
					$(this).addClass('vertical-image');
				}
		
				let rotationDegrees = [0, 5, -5, 0][index]; // Ottimizzazione del codice per la rotazione
		
				// Programma la visualizzazione e l'animazione delle immagini
				setTimeout(() => {
					$(this).css({
						'transform': `translate(-50%, -50%) rotate(${rotationDegrees}deg)`,
						'display': 'block',
						'z-index': 19
					});
		
					if (index === 3) {
						prepareLastImage($(this));
					}
				}, delayToShow);
		
				delayToShow += delayBetweenImages * 1000; // Converti in millisecondi
			});
		
			// Inizia l'inizializzazione della pagina dopo l'ultima animazione
			setTimeout(initializePage, totalDuration * 1000); // Converti in millisecondi
		}
		
		function prepareLastImage(image) {
			// Modifica il ritardo e la durata dell'animazione
			gsap.to(image, {
				delay: 0.3, // Ritardo ridotto
				duration: 0.08, // Durata ridotta per adattarsi al nuovo limite di tempo
				width: '100vw',
				height: '100vh',

				ease: "back.inOut",
				onComplete: function() {
					$('#page').addClass('page-ready');
				}
			});
		}

	
		function initializePage() {
			// Attiva le funzioni di interazione della pagina
			allClick();
			smoothScroll();
		

		
			setTimeout(() => {
				// Ora che l'animazione è completata, rendi visibile #page
				prepareLastImage($('#intro-images .intro-image.last-image'));
				$('#intro-images').css('z-index', '9');
				$('#page').addClass('init');
		
				// Rimuovi il contenitore delle immagini introduttive dopo un breve momento
				$('#intro-images .intro-image:not(.last-image)').fadeOut(500, function() {
					$(this).remove();
				});
		
				// Controllo della larghezza della viewport
				if (window.innerWidth < 768) {
					// Solo se la larghezza della viewport è minore di 768px
					$('.site-tools').css('opacity', 1);
					$('.site-logo img').css('opacity', 1);
					$('site-logo').css('overflowX', 'hidden');
				}
		
			}, 1000); // Assumi che questo delay corrisponda alla durata dell'ingrandimento dell'ultima immagine
		}
		
		// Avvia le animazioni introduttive
		//wait 0.5 seconds before starting the animation
		setTimeout(() => {
		showIntroImages();
		}, 500);
		setTimeout(() => {
			revealImages();
			if (window.innerWidth > 768) {
				revealElement();	
			}
		}, 3000);
		setTimeout(() => {
			// Termina l'animazione di loading e inizia l'inizializzazione della pagina...
			initializePage();
		
		}, 4000);
		
		
	});
	
	// Code to run when mouse moves

	$( document ).mousemove(function(event) {

		// Decorations (header)
		$('.header-images [class^="decor"], .header-mask-image [class^="decor"]').each(function(index, element) {
			var depth = 70,
				xPos = (event.pageX - window.innerWidth / 2) / depth,
				yPos = (event.pageY - window.innerHeight / 2) / depth,
				decor = element;
	
			index++;

			gsap.to(
				decor,
				1,
				{
					x: xPos * index,
					y: yPos * index,
					ease: 'Power1.easeOut'
				}
			);
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Decorations (no header)
		var decors = gsap.utils.toArray('[class^="decor"]:not(.norotate)');

		if ( decors.length > 0 ) {
			decors.forEach((decor) => {
				var angle = Math.floor(Math.random() * 360) - 180;

				gsap.to(
					decor,
					{
						duration: 4,
						rotation: angle,
						immediateRender: false,
						scrollTrigger: {
							trigger: decor,
							toggleActions: 'restart pause resume reverse'
						}
					}
				);
			});
		}

		// Backgrounds
/* 		var sections = gsap.utils.toArray('.section');

		if ( sections.length > 0 ) {
			sections.forEach((section) => {
				if ( section.getAttribute('data-bgcolor' ) !== null) {
					var bgcolor = section.getAttribute('data-bgcolor');

					gsap.to(
						'#content',
						{
							backgroundColor: bgcolor,
							immediateRender: false,
							scrollTrigger: {
								trigger: section,
								start: 'top bottom',
								end: '+=100%',
								scrub: true,
								toggleActions: 'restart pause reverse pause'
							}
						}
					);
				}
			});
		} */

		// Menu
		var menu = gsap.timeline({
			paused: true,
			onStart: function() {
				$('body').addClass( 'open-menu' );
			},
			onReverseComplete: function() {
				$('body').removeClass( 'open-menu' );

				submenus.forEach((submenu, index) => {
					tween[index].reverse();
				});
			}
		});

		menu.to(
			'.menu-blend',
			{
				duration: 0.5,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'#menu-offcanvas',
			{
				duration: 1,
				x: 0,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'-=0.1'
		).to(
			'.menu-bottom',
			{
				opacity: 1,
				ease: 'Power2.inOut'
			}
		);

		// Sub Menus
		var submenus = gsap.utils.toArray('.sub-menu'),
			tween = [];

		submenus.forEach((submenu, index) => {
			tween[index] = gsap.from(
				submenu,
				{
					duration: 0.25,
					height: 0,
					paused: true,
					reversed: true,
					ease: 'Power2.inOut'
				}
			);
		});

		// Single letter animation
		function createTextAnimations() {
			$('.letter-animation').each(function(index) {
				let triggerElement = $(this);
				let targetElement = $(this).find('span');
			
				let tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom center',
						toggleActions: 'restart pause resume reverse'
					}
				});

				tl.from(targetElement, {
					duration: 0.6,
					ease: 'Circ.easeOut',
					yPercent: 80,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});
		}

		createTextAnimations();

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		if ( titles.length > 0 ) {
			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 0.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});
		}
		var titles_side = gsap.utils.toArray('.slide_side');

		if ( titles_side.length > 0 ) {
			titles_side.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 0.5,
						xPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});
		}
		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		if ( texts.length > 0 ) {
			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top bottom';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 0.3,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: 'top bottom',
							duration: 0.2,
							toggleActions: 'restart pause resume reverse'
						}
					}
				);
			});
		}

		// Extra words (discover)
		var extra = $('#extra');

		if ( extra.length > 0 ) {
			var items = gsap.utils.toArray('.split span');

			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.split',
					start: 'top center',
					toggleActions: 'restart pause reverse pause'
				}
			});

			items.forEach((item) => {
				tl.to(
					item,
					{
						duration: 0.5,
						ease: 'none',
						opacity: 1
					}
				);
			});
		}

		

		/* GSAP Animations - End */

		// Activate/Deactivate sub-menus

		$('#primary-menu').find('a[href="#"]').each(function(i) {
			$(this).on('click', function(e) {
				e.preventDefault();

				tween[i].reversed() ? tween[i].play() : tween[i].reverse();
			});
		});

		// Hide/show menu off canvas
		hamburger.on('click', function(e) {
			e.preventDefault();
			
			menuButton.toggleClass('collapsed');
			const menuTextSVG = document.querySelector('#menu-label text textPath'); // Assicurati che l'selector corrisponda al tuo caso d'uso
		
			if (!menuButton.hasClass('collapsed')) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				menu.play();
				menuTextSVG.textContent = 'Chiudi'; // Cambia il testo in 'Chiudi' quando il menu è aperto
			} else {
				menu.reverse();
				menuTextSVG.textContent = 'Menu'; // Torna a 'Menu' quando il menu è chiuso
			}
		});
		

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});



})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

// Menu rotation





/* Swiper - Begin */

// Reviews slideshow

const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    allowTouchMove: false,
    speed: 8000,
    autoplay: {
        delay: 0,
        disableOnInteraction: false,
    },
    on: {
        init: function () {
            let totalSlides = this.slides.length;
            let quarterSlideIndex = Math.floor(totalSlides / 8);
            this.slideTo(quarterSlideIndex, 0); // 0 è per non avere animazione durante il posizionamento iniziale

            // Continuare la rotazione da dove è stata inizializzata
            this.params.speed = 8000;
            this.autoplay.start();
        }
    }
});

// Social slideshow

const socialSlider = new Swiper('#social .social-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	allowTouchMove: false,
	autoplay: {
		delay: 1000000000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 1,
			slidesPerGroup: 1,
			speed: 2000
		}
	},
});
document.addEventListener('DOMContentLoaded', function() {
   // Funzione per inizializzare lo slider della galleria
   function initGallerySlider() {
	const track = document.querySelector('.slider-track');
	const slides = Array.from(document.querySelectorAll('.slider-slide'));
	let currentIndex = 0;
	const slidesPerPage = 3;
	const totalSlides = Math.ceil(slides.length / slidesPerPage);

	function updateSlider() {
		const transformValue = `translateX(-${currentIndex * 100}%)`;
		track.style.transform = transformValue;

	}

	function showNextSlide() {
		currentIndex = (currentIndex + 1) % totalSlides;
		updateSlider();
	}

	// Inizializza lo slider
	updateSlider();

	// Autoplay
	setInterval(showNextSlide, 4000);

	// Touch events
	let touchStartX = 0;
	let touchEndX = 0;

	function handleTouchStart(e) {
		touchStartX = e.changedTouches[0].screenX;
	}

	function handleTouchMove(e) {
		touchEndX = e.changedTouches[0].screenX;
	}

	function handleTouchEnd() {
		if (touchEndX < touchStartX) {
			showNextSlide();
		}
	}

	track.addEventListener('touchstart', handleTouchStart, false);
	track.addEventListener('touchmove', handleTouchMove, false);
	track.addEventListener('touchend', handleTouchEnd, false);
}

initGallerySlider();

   // Initialize Lightbox
   const lightbox = document.getElementById('lightbox');
   const lightboxContent = document.querySelector('.lightbox-content');
   const galleryItems = document.querySelectorAll('.gallery-item');
   let currentLightboxIndex = 0;

   function showLightbox(index) {
	   lightbox.style.display = 'flex';
	   lightboxContent.src = galleryItems[index].href;
	   currentLightboxIndex = index;
   }

   function hideLightbox() {
	   lightbox.style.display = 'none';
   }

   function showPrev() {
	   currentLightboxIndex = (currentLightboxIndex === 0) ? galleryItems.length - 1 : currentLightboxIndex - 1;
	   lightboxContent.src = galleryItems[currentLightboxIndex].href;
   }

   function showNext() {
	   currentLightboxIndex = (currentLightboxIndex === galleryItems.length - 1) ? 0 : currentLightboxIndex + 1;
	   lightboxContent.src = galleryItems[currentLightboxIndex].href;
   }

   galleryItems.forEach((item, index) => {
	   item.addEventListener('click', (e) => {
		   e.preventDefault();
		   showLightbox(index);
	   });
   });

   document.querySelector('.lightbox-close').addEventListener('click', hideLightbox);
   document.querySelector('.lightbox-prev').addEventListener('click', showPrev);
   document.querySelector('.lightbox-next').addEventListener('click', showNext);

   // Hide lightbox when clicking outside the image
   lightbox.addEventListener('click', (e) => {
	   if (e.target === lightbox) {
		   hideLightbox();
	   }
   });

   // Hide lightbox with ESC key and navigate with arrow keys
   document.addEventListener('keydown', (e) => {
	   if (e.key === 'Escape') {
		   hideLightbox();
	   } else if (e.key === 'ArrowLeft') {
		   showPrev();
	   } else if (e.key === 'ArrowRight') {
		   showNext();
	   }
   });
});


/* Swiper - End */
